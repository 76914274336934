import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './tree-folder.css';
import { emptyNode } from './example';
import { getSubTree } from '../../../../redux/actions/api';

const arrayToTree = (arr, id_upline = null, visited = new Set()) =>
  arr
    .filter((item) => item.id_upline === id_upline )
    .map((child) => {
      if (visited.has(child.id_member)) {
        return null; 
      }

      visited.add(child.id_member); 

      let childs = arrayToTree(arr, child.id_member, visited);
      const hasChild = child.jumlah_child_kiri > 0 || child.jumlah_child_kanan > 0;

      // If no children and no defined child count, create empty child nodes
      if (childs.length === 0 && !hasChild) {
        childs = [
          { ...emptyNode, sisi: 'L' },
          { ...emptyNode, sisi: 'R' },
        ];
      }

      // If there is only one child, create the opposite empty child node
      if (childs.length === 1) {
        childs = [
          ...childs,
          { ...emptyNode, sisi: childs[0].sisi === 'L' ? 'R' : 'L' },
        ];
      }

      return {
        ...child,
        hasChild: hasChild,
        isOpen: childs?.length > 0,
        childs: childs?.length ? childs : null,
      };
    }) .filter((item) => item !== null);;

const FolderTree = ({
  downlineOpts,
  data,
  setCariId,
  setQuery,
  downlineKanan,
  downlineKiri,
  upline,
}) => {
  const [treeData, setTreeData] = useState([]);
  useEffect(() => {
    if (data?.length > 0) {
      const [data0, ...dataAll] = data;
      const tmp = arrayToTree([{ ...data0, id_upline: null }, ...dataAll]);
      setTreeData(tmp);
    }
  }, [data]);

  const getDownlineChilds = async (data, id) => {
    await getSubTree(id).then(({ result }) => {
      const subTree = arrayToTree([{ ...data, id_upline: null }, ...result]);
      setTreeData(manipulasi(treeData, id, subTree[0].childs));
    });
  };

  const manipulasi = (data, key, childs) => {
    let tmp = [];
    for (const d of data) {
      if (d.id_member === key) {
        // payload childs maksudnya append childs
        let thisChilds = d.childs;
        let isOpen = !d.isOpen;
        if (childs) {
          thisChilds = childs;
          isOpen = true;
        } else {
          if (isOpen && d.hasChild && !d.childs) getDownlineChilds(d, key);
        }
        tmp.push({ ...d, isOpen: isOpen, childs: thisChilds });
      } else {
        tmp.push({
          ...d,
          childs: d.childs?.length ? manipulasi(d.childs, key, childs) : null,
        });
      }
    }
    return tmp;
  };

  const ftreeClick = (data, id_member, hasChild) => {
    if (!hasChild) return;
    setTreeData(manipulasi(data, id_member));
  };
  return (
    <div className="">
      <div className="d-block d-lg-flex p-3">
        <div className="d-flex flex-column flex-md-row mb-2 mb-md:0">
          <div style={{ width: '250px', maxWidth: '100%' }}>
            <Select
              placeholder="Cari ID/Nama"
              defaultOptions
              cacheOptions
              onChange={({ value }) => setCariId(value)}
              onInputChange={(val) => {
                setQuery(val);
              }}
              options={downlineOpts}
            />
          </div>
        </div>
        <div className="w-100 mb-2">
          <div className="d-flex justify-content-start justify-content-lg-end flex-row w-100">
            <button
              className="btn btn-sm btn-primary-f mx-1 px-2"
              onClick={() => setCariId(downlineKiri)}
              disabled={data?.[0]?.downlinekiri_id_member ? false : true}>
              DownL
            </button>
            <button
              className="btn btn-sm btn-primary-f mx-1 px-2"
              onClick={() => setCariId(upline)}
              disabled={data?.[0]?.root ? true : false}>
              UP
            </button>

            <button
              className="btn btn-sm btn-primary-f mx-1 px-2"
              onClick={() => setCariId(downlineKanan)}
              disabled={data?.[0]?.downlinekanan_id_member ? false : true}>
              DownR
            </button>
          </div>
        </div>
      </div>
      
      <div className="ftree-wrapper">
        <FTree
          allTree={treeData}
          tree={treeData}
          toggleClick={(data, key, hasChild) => ftreeClick(data, key, hasChild)}
        />
      </div>
    </div>
  );
};

const FTree = ({ allTree, tree, toggleClick }) => {
  return (
    <ul className="ftree">
      {tree
        .sort((a, b) => (a.sisi > b.sisi ? 1 : b.sisi > a.sisi ? -1 : 0))
        ?.map(
          ({
            id_member,
            nama,
            sisi,
            hasChild,
            posisi,
            childs,
            isOpen,
            jumlah_pair,
            jumlah_child_kiri,
            jumlah_child_kanan,
          }) => {
            return (
              <li>
                <div
                  className={`d-table ftree-group-label ${!id_member && 'ftree-group-label-empty'
                    }`}>
                  {isOpen && hasChild && !childs ? (
                    <div
                      className={`d-table-cell pr-2`}
                      style={{ width: '25px', height: '25px' }}>
                      <div
                        style={{ width: '0.9rem', height: '0.9rem' }}
                        className="spinner-border text-secondary spinner-border-sm mt-1"
                        role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    hasChild && (
                      <div
                        onClick={() =>
                          toggleClick(allTree, id_member, hasChild)
                        }
                        className="d-table-cell text-nowrap pr-2 cursor-pointer"
                        style={{ width: '25px', height: '25px' }}>
                        <i
                          className={`fas fa-${isOpen ? 'minus' : 'plus'
                            }-square`}
                        />
                      </div>
                    )
                  )}
                  <div className="table-cell text-nowrap">
                    <div className="dropdown">
                      <div
                        id={`dd-${id_member}`}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="d-table-cell text-nowrap pr-2">
                        {id_member
                          ? `${id_member} - ${nama} - ${sisi || ''}`
                          : ''}
                        <span
                          className={`text-nowrap ml-2 px-1 ${posisi?.toLowerCase() === 'platinum'
                            ? 'pl-color'
                            : 'sv-color'
                            }`}>
                          {posisi}
                        </span>
                      </div>
                      <div
                        className="dropdown-menu dd-content p-2"
                        aria-labelledby={`dd-${id_member}`}>
                        Detail :
                        <div className="dd-content-in">
                          <div>ID : {id_member}</div>
                          <div>Nama : {nama}</div>
                          <div>Posisi : {posisi}</div>
                          <div>Sisi : {sisi}</div>
                          <div>Pair : {jumlah_pair}</div>
                          <div>Downline L : {jumlah_child_kiri}</div>
                          <div>Downline R : {jumlah_child_kanan}</div>
                          <div>
                            Sisa Pair L :{' '}
                            {Math.abs(jumlah_pair - jumlah_child_kiri)}
                          </div>
                          <div>
                            Sisa Pair R :{' '}
                            {Math.abs(jumlah_pair - jumlah_child_kanan)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {childs?.length && isOpen > 0 ? (
                  <FTree
                    allTree={allTree}
                    tree={childs}
                    toggleClick={toggleClick}
                  />
                ) : (
                  ''
                )}
              </li>
            );
          }
        )}
    </ul>
  );
};

export default FolderTree;
